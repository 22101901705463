import React, { CSSProperties, ReactNode, useState } from 'react'
import clsx from 'clsx'
import { FieldProps, getIn } from 'formik'
import { MenuItem, Tooltip, Box } from '@material-ui/core'
import LiquidSelect from '../LiquidSelect'
import { LiquidSelectProps } from '../LiquidSelect/LiquidSelect'
import LiquidFormLabel from '@src/components/LiquidFormLabel'
import LiquidFormHelpText from '@src/components/LiquidFormHelpText'
import hasTouch from '@src/styles/utils/hasTouch'
export interface LiquidSelectAdapterProps
  extends FieldProps,
    LiquidSelectProps {
  rootClass?: string
  rootStyle?: CSSProperties
  label?: ReactNode
  required?: boolean
  help?: ReactNode
  options: { value: any; label: React.ReactNode; tooltip?: string }[]
  native?: boolean
  hasPlaceHolder?: boolean
  dataScrub?: boolean
}
interface MySelectDisplayProps extends React.HTMLAttributes<HTMLDivElement> {
  'data-scrub'?: string
}

const LiquidSelectAdapter: React.FC<LiquidSelectAdapterProps> = ({
  rootClass,
  rootStyle,
  size = 'medium',
  field,
  form,
  label,
  required,
  help,
  options,
  native = false,
  hasPlaceHolder = false,
  onOpen,
  onClose,
  dataScrub = false,
  ...otherProps
}) => {
  const error = getIn(form.errors, field.name)
  const touched = getIn(form.touched, field.name)
  const hasError = error && touched
  const shouldRenderNative = native || hasTouch
  const [showTooltip, setShowTooltip] = useState(false)
  if (shouldRenderNative && options.length > 0 && options[0].value) {
    options.unshift({ label: '', value: '' })
  }
  return (
    <div
      className={clsx({
        [String(rootClass)]: rootClass,
      })}
      style={rootStyle}
    >
      {label && (
        <LiquidFormLabel
          htmlFor={field.name}
          size={size}
          required={required}
          error={hasError}
        >
          {label}
        </LiquidFormLabel>
      )}
      <LiquidSelect
        id={field.name}
        {...field}
        size={size}
        native={shouldRenderNative}
        error={Boolean(hasError)}
        aria-describedby={(hasError || help) && `${field.name}-help-text`}
        onOpen={(e) => {
          if (onOpen) onOpen(e)
          setShowTooltip(true)
        }}
        onClose={(e) => {
          if (onClose) onClose(e)
          setShowTooltip(false)
        }}
        {...otherProps}
        SelectDisplayProps={
          {
            'data-scrub': dataScrub ? 'true' : undefined,
          } as MySelectDisplayProps
        }
      >
        {options.map(({ label, value, tooltip }) => {
          return !shouldRenderNative ? (
            <MenuItem
              key={value}
              value={value}
              disabled={value === '' && hasPlaceHolder}
              data-scrub={dataScrub ? true : undefined}
            >
              {!!tooltip ? (
                <Tooltip
                  title={tooltip}
                  placement="right"
                  arrow
                  disableHoverListener={!showTooltip}
                >
                  <Box width="100%">{label}</Box>
                </Tooltip>
              ) : (
                label
              )}
            </MenuItem>
          ) : (
            <option key={value} value={value}>
              {label}
            </option>
          )
        })}
      </LiquidSelect>
      {(hasError || help) && (
        <LiquidFormHelpText
          id={`${field.name}-help-text`}
          error={Boolean(hasError)}
        >
          {hasError ? error : help ? help : null}
        </LiquidFormHelpText>
      )}
    </div>
  )
}

export default LiquidSelectAdapter
