import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Theme,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import LiquidInput from '@src/components/LiquidInput'
import ResponsiveModal, { ModalSizes } from '@src/components/ResponsiveModal'
import Search from '@src/icons/SearchIcon'
import { CURRENT_USER } from '@src/queries/CurrentUserQuery'
import { DEALERS_QUERY } from '@src/queries/DealersQuery'
import { DealerDataFragment } from '@src/queries/DealersQuery.generated'
import { useUpdateUserSitePreferenceMutation } from '@src/mutations/UpdateUserSitePreferenceMutations.generated'
import { ValidMaterialPricingDetailFragment } from '@src/fragments/ProductPricing.generated'
import messages from '@src/utils/messages'
import { extractData } from '@utils/errorHandler'
import { useCurrentUser } from '@utils/useCurrentUser'
import { useUserSession } from '@utils/useUserSession'
import clsx from 'clsx'
import React, { FC, useReducer, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDebounce } from 'react-use'

import DealerTableFetchData from './DealerTableFetchData'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: '8vw',
    backgroundColor: theme.palette.background.default,
  },
  closeButton: {
    height: theme.spacing(10),
    width: theme.spacing(10),
    margin: theme.spacing(2),
  },
  footer: {
    width: '100%',
    height: 40,
    paddingLeft: theme.spacing(5),
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    margin: theme.spacing(4, 0, 4),
  },
  selectButton: {
    width: '100%',
    marginRight: theme.spacing(5),
  },
  listContainer: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    overflow: 'hidden',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: 80,
    marginLeft: 20,
    marginRight: 20,
    marginTop: 16,
    minHeight: 258,
  },
  inputContainer: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: 32,
      marginRight: 32,
    },
    marginLeft: 16,
    marginRight: 16,
    marginTop: '24px',
  },
  input: {
    width: '100%',
  },
  disclaimerContainer: {
    width: 'auto',
    whiteSpace: 'pre-line',
  },
  checkbox: {
    fontSize: theme.spacing(6),
  },
  checkboxWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchIconContainer: {
    paddingRight: 14,
  },
}))

interface ButtonText {
  id: string
  defaultMessage: string
}

interface SelectDealerModal {
  open: boolean
  dealers?: DealerDataFragment[]
  disabled?: boolean
  isSavingDealerPreference?: boolean
  onClose: () => void
  values?: Record<string, unknown>
  materialPricing?: ValidMaterialPricingDetailFragment[]
  setFieldValue?: (field: string, value: string) => void
  setIsModalOpen?: (value: React.SetStateAction<boolean>) => void
  onDealerSelect?: Function
  onDealerSelectCallback?: Function
  onSearch?: (term: string) => void
  btnTextOverride?: ButtonText
  error?: any
}

const DefaultButton: FC<{
  isSettingDealer: boolean
}> = ({ isSettingDealer }) => {
  return (
    <>
      {isSettingDealer ? (
        <FormattedMessage {...messages.SET_PREFERRED_DEALER} />
      ) : (
        <FormattedMessage {...messages.SELECT_VIEW_PRICING} />
      )}
    </>
  )
}

const SeeBuyingOptionsText: FC<{
  dealerCount: number | undefined
}> = ({ dealerCount }) => {
  const { formatMessage } = useIntl()

  const linkText = formatMessage(messages.SEE_ALL_BUYING_OPTIONS)

  if (Boolean(dealerCount)) {
    return <>{`${linkText} (${dealerCount})`}</>
  }

  return <>{`${linkText}`}</>
}

const selectedDealerIdInitialState = null
const selectedDealerIdReducer = (
  state: string | null,
  action: { type: string; payload: string | null }
) => {
  // if type is SET_SELECTED_DEALER_ID return payload, else return state
  return action.type === 'SET_SELECTED_DEALER_ID' ? action.payload : state
}

const SelectDtaqDealerModal: FC<SelectDealerModal> = ({
  open,
  onClose,
  values,
  materialPricing,
  setFieldValue,
  setIsModalOpen,
  onDealerSelect,
  onDealerSelectCallback,
  onSearch,
  isSavingDealerPreference = true,
  disabled,
  dealers,
  btnTextOverride,
}) => {
  const classes = useStyles()
  const intl = useIntl()
  const [selectedDealerId, setSelectedDealerId] = useReducer(
    selectedDealerIdReducer,
    selectedDealerIdInitialState
  )

  const [selectedDealerName, setSelectedDealerName] = useState<
    Maybe<string> | undefined
  >(dealers?.[0]?.organizationName ?? undefined)
  const { currentUserState, userId } = useCurrentUser()
  const { userSession } = useUserSession()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [debouncedSearchTerm, setDebouncedSearchTerm] =
    useState<string>(searchTerm)

  const [updateUserSitePreference, { loading }] =
    useUpdateUserSitePreferenceMutation({
      refetchQueries: [
        { query: CURRENT_USER },
        {
          query: DEALERS_QUERY,
          context: { userSession },
          variables: {
            countryCode: userSession?.country,
            stateCode: String(currentUserState),
          },
        },
      ],
      awaitRefetchQueries: true,
    })

  const selectDealer = (
    id: string,
    organizationName: Maybe<string> | undefined
  ) => {
    const selected = id !== selectedDealerId ? id : null
    setSelectedDealerId({ type: 'SET_SELECTED_DEALER_ID', payload: selected })
    const selectedName =
      organizationName !== selectedDealerName ? organizationName : undefined
    setSelectedDealerName(selectedName)
  }

  const updatePreferredDealerAndClose = async (dealerId: string | null) => {
    try {
      if (!userId) throw Error('userId is required to update dealer')
      if (!dealerId) throw Error('dealerId is required to update dealer')

      await updateUserSitePreference({
        variables: { userId, data: { dealerId } },
      })

      if (onDealerSelectCallback) onDealerSelectCallback()

      return onClose()
    } catch (error) {
      const parsedError = extractData(error as any)
      console.error(parsedError.displayableError)
      return onClose()
    }
  }

  useDebounce(
    () => {
      onSearch && searchTerm && onSearch(searchTerm)
      setDebouncedSearchTerm(searchTerm)
    },
    500,
    [searchTerm]
  )

  return (
    <ResponsiveModal
      open={open}
      onClose={onClose}
      renderTitle={() => (
        <Typography variant="h2">
          {isSavingDealerPreference ? (
            <FormattedMessage {...messages.SELECT_PREFERRED_DEALER} />
          ) : (
            <SeeBuyingOptionsText dealerCount={dealers?.length} />
          )}
        </Typography>
      )}
      size={ModalSizes.Large}
    >
      <>
        {userSession.country === 'JP' && (
          <Grid
            container
            className={clsx(
              classes.inputContainer,
              classes.disclaimerContainer
            )}
          >
            {isSavingDealerPreference ? (
              <FormattedMessage {...messages.DEALER_SELECT_MODAL_DISCLAIMER} />
            ) : (
              <FormattedMessage
                {...messages.DEALER_UPDATE_PRICING_DISCLAIMER}
              />
            )}
          </Grid>
        )}
        <Grid className={classes.inputContainer}>
          <LiquidInput
            className={classes.input}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder={intl.formatMessage(
              messages.ENTER_DEALER_NAME_OR_LOCATION
            )}
            size="small"
            value={searchTerm}
            endAdornment={
              <InputAdornment
                position="end"
                className={classes.searchIconContainer}
              >
                <Search color="primary" />
              </InputAdornment>
            }
          />
        </Grid>
        <Box className={classes.listContainer}>
          <DealerTableFetchData
            selectDealer={selectDealer}
            selectedDealerId={selectedDealerId}
            searchTerm={debouncedSearchTerm}
          />
        </Box>
        <Grid
          container
          className={classes.footer}
          direction="row"
          id="footer"
          justifyContent="flex-end"
          alignItems="center"
          wrap="nowrap"
        >
          <Grid item sm={4} md={4}>
            <Grid container justifyContent="center">
              <Button
                size="large"
                color="primary"
                variant="outlined"
                className={classes.selectButton}
                onClick={onClose}
                data-testid="select-dealer-cancel"
                id="select-dealer-submit"
              >
                <FormattedMessage {...messages.CANCEL} />
              </Button>
            </Grid>
          </Grid>
          <Grid item sm={4} md={4}>
            <Grid container justifyContent="center">
              <Button
                size="large"
                color="primary"
                variant="contained"
                className={classes.selectButton}
                onClick={() =>
                  onDealerSelect &&
                  values &&
                  materialPricing &&
                  setFieldValue &&
                  setIsModalOpen
                    ? onDealerSelect(
                        selectedDealerId,
                        values,
                        materialPricing,
                        setFieldValue,
                        setIsModalOpen,
                        selectedDealerName
                      )
                    : updatePreferredDealerAndClose(selectedDealerId)
                }
                disabled={disabled || loading || !selectedDealerId}
                data-testid="select-dealer-submit"
                id="select-dealer-submit"
              >
                {btnTextOverride ? (
                  <FormattedMessage {...btnTextOverride} />
                ) : (
                  <DefaultButton isSettingDealer={isSavingDealerPreference} />
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </>
    </ResponsiveModal>
  )
}

export default SelectDtaqDealerModal
