import React, { useRef, useEffect, useState } from 'react'
import { sendVideoInteractionEvent } from '@src/utils/analytics'

interface VideoPlayerProps {
  videoUrl: string
  posterUrl: string
  css: string
  name: string | undefined
  brand: string | null | undefined
  productId: string | undefined
  autoPlay?: boolean
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  videoUrl,
  posterUrl,
  css,
  name,
  brand,
  productId,
  autoPlay,
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [videoCurrentTime, setVideoCurrentTime] = useState(0)
  const [visible, setVisible] = useState(false)
  const videoDuration = Math.round(videoRef.current?.duration || 0)
  const videoPercent = Math.round((videoCurrentTime / videoDuration) * 100) || 0

  const onIntersection: IntersectionObserverCallback = (entries) => {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) {
        setVisible(false)
        videoRef.current?.pause()
      } else {
        setVisible(true)
      }
    })
  }

  const videoGAevent = (action: string) => {
    sendVideoInteractionEvent({
      action,
      videoCurrentTime,
      videoDuration,
      videoPercent,
      videoUrl,
      visible,
      productId,
      productName: name,
      productBrand: brand,
    })
  }

  useEffect(() => {
    if (videoPercent === 25 || videoPercent === 50 || videoPercent === 75) {
      videoGAevent('progress')
    }
    if (videoPercent === 100) {
      videoGAevent('complete')
    }
  }, [videoPercent])

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    }

    const observer = new IntersectionObserver(onIntersection, options)

    if (videoRef.current) {
      observer.observe(videoRef.current)
    }

    const onVisibilityChange = () => {
      if (typeof document !== undefined && document.hidden) {
        videoRef.current?.pause()
      }
    }

    typeof document !== undefined &&
      document.addEventListener('visibilitychange', onVisibilityChange)

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current)
      }
      typeof document !== undefined &&
        document.removeEventListener('visibilitychange', onVisibilityChange)
    }
  }, [videoRef])

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        className={css}
        ref={videoRef}
        controls
        autoPlay={!!autoPlay}
        preload="metadata"
        poster={posterUrl}
        onTimeUpdate={() =>
          setVideoCurrentTime(Math.round(videoRef.current?.currentTime || 0))
        }
        onPlay={() => videoGAevent('start')}
        onPause={() => videoPercent !== 100 && videoGAevent('pause')}
      >
        <source src={videoUrl} type="video/mp4" />
      </video>
    </>
  )
}

export default VideoPlayer
