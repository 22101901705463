// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type UpdateUserSitePreferenceMutationVariables = Types.Exact<{
  userId: Types.Scalars['String'];
  data: Types.UpdateUserSitePreferenceInput;
}>;


export type UpdateUserSitePreferenceMutation = { __typename?: 'Mutation', updateUserSitePreference?: boolean | null };


export const UpdateUserSitePreferenceDocument = gql`
    mutation UpdateUserSitePreference($userId: String!, $data: UpdateUserSitePreferenceInput!) {
  updateUserSitePreference(userId: $userId, data: $data)
}
    `;
export type UpdateUserSitePreferenceMutationFn = ApolloReactCommon.MutationFunction<UpdateUserSitePreferenceMutation, UpdateUserSitePreferenceMutationVariables>;
export type UpdateUserSitePreferenceComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateUserSitePreferenceMutation, UpdateUserSitePreferenceMutationVariables>, 'mutation'>;

    export const UpdateUserSitePreferenceComponent = (props: UpdateUserSitePreferenceComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateUserSitePreferenceMutation, UpdateUserSitePreferenceMutationVariables> mutation={UpdateUserSitePreferenceDocument} {...props} />
    );
    

/**
 * __useUpdateUserSitePreferenceMutation__
 *
 * To run a mutation, you first call `useUpdateUserSitePreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSitePreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSitePreferenceMutation, { data, loading, error }] = useUpdateUserSitePreferenceMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserSitePreferenceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserSitePreferenceMutation, UpdateUserSitePreferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateUserSitePreferenceMutation, UpdateUserSitePreferenceMutationVariables>(UpdateUserSitePreferenceDocument, options);
      }
export type UpdateUserSitePreferenceMutationHookResult = ReturnType<typeof useUpdateUserSitePreferenceMutation>;
export type UpdateUserSitePreferenceMutationResult = ApolloReactCommon.MutationResult<UpdateUserSitePreferenceMutation>;
export type UpdateUserSitePreferenceMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserSitePreferenceMutation, UpdateUserSitePreferenceMutationVariables>;