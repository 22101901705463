import {
  AccordionDetails,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  Theme,
  Typography,
} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import clsx from 'clsx'
import { ElementType, ReactElement, ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

// Common Accordion
// Provides default styling in alignment with current design system:
// https://www.figma.com/design/Qakbf0ck9WM6GT0rkMvihB/Design-System-%2F-Component-Library?node-id=10554-21663&node-type=frame&t=HUh8PHLJrYliAPLw-0
//
// Accepts a custom accordion summary component while still providing core accordion functionality and best practices

const useStyles = makeStyles((theme: Theme) => {
  return {
    accordionsWrapper: {
      '& div:not(:last-child)': {
        borderBottom: 'none',
      },
    },
    accordionBorder: {
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
      borderTop: `1px solid ${theme.palette.grey[400]}`,
    },
  }
})

const Accordion = withStyles((theme: Theme) => ({
  root: {
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
      paddingBottom: theme.spacing(3),
    },
  },
  expanded: {},
}))(MuiAccordion)

const AccordionSummary = withStyles((theme: Theme) => ({
  root: {
    borderRadius: 0,
    minHeight: 48,
    '&$expanded': {
      minHeight: 48,
    },
    '&.Mui-focusVisible': {
      '& h2': {
        color: theme.palette.common.white,
      },
      '& .MuiSvgIcon-root': {
        color: theme.palette.common.white,
      },
    },
  },
  content: {
    '&$expanded': {
      margin: theme.spacing(3, 0),
    },
  },
  expanded: {},
}))(MuiAccordionSummary)

// Coordinates styling for multiple accordions
// Can be utilized for handling multiple accordion state, if/when needed
export const CommonAccordionsWrapper = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.accordionsWrapper}>{children}</div>
}

type FormattedMessageType = ElementType<typeof FormattedMessage>

interface CommonAccordionProps {
  summary: ReactElement<FormattedMessageType> | ReactElement
  summaryId: string // required for a11y best practices
  summaryAriaControls: string // required for a11y best practices
  details: ReactNode // accordion content
  defaultExpanded?: boolean
  expandedCallback?: Function
  overrideClasses?: {
    accordion?: string
    summary?: string
    details?: string
  }
  // Optional improvement to performance by restricting accordion content mounting until expanded
  // See: https://v4.mui.com/components/accordion/#performance for more details
  unmountOnExit?: boolean
  expanded?: boolean
}

const CommonAccordion = ({
  summary,
  summaryId,
  summaryAriaControls,
  details,
  defaultExpanded = false,
  expandedCallback = () => ({}),
  overrideClasses = {},
  unmountOnExit = false,
  expanded,
}: CommonAccordionProps) => {
  const classes = useStyles()
  // If a FormattedMessage is passed in the component will default to design system styling
  // otherwise, it will render a fully custom summary component
  const shouldDisplayDefaultStyles = summary?.type === FormattedMessage

  // Allows parent components that use CommonAccordion to react to expansion state changes
  const onChangeCallbackHandler = (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    expandedCallback(isExpanded)
  }

  return (
    <Accordion
      classes={{ root: overrideClasses.accordion }}
      className={clsx({
        [classes.accordionBorder]: shouldDisplayDefaultStyles,
      })}
      defaultExpanded={defaultExpanded}
      square
      onChange={onChangeCallbackHandler}
      TransitionProps={{ unmountOnExit }}
      expanded={expanded}
    >
      {shouldDisplayDefaultStyles && (
        <AccordionSummary
          classes={{ root: overrideClasses.summary }}
          expandIcon={<ExpandMoreIcon color="primary" />}
          id={summaryId}
          aria-controls={summaryAriaControls}
        >
          <Typography variant="h2">{summary}</Typography>
        </AccordionSummary>
      )}

      {!shouldDisplayDefaultStyles && (
        // Does not use styled AccordionSummary to allow for custom appearance
        <MuiAccordionSummary
          classes={{ root: overrideClasses.summary }}
          id={summaryId}
          aria-controls={summaryAriaControls}
        >
          {summary}
        </MuiAccordionSummary>
      )}

      <AccordionDetails classes={{ root: overrideClasses.details }}>
        {details}
      </AccordionDetails>
    </Accordion>
  )
}

export default CommonAccordion
