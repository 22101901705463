import Icon, { BaseIconProps } from './Icon'
function SearchIcon({ ...props }: BaseIconProps) {
  return (
    <Icon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        viewBox="0 0 19.97 19.95"
        {...props}
      >
        <path d="m19.63 18.06-4.45-4.45c2.85-3.71 2.15-9.02-1.56-11.87S4.6-.4 1.75 3.31-.4 12.33 3.31 15.18a8.45 8.45 0 0 0 10.31 0l4.45 4.45c.21.21.49.33.79.33.29 0 .58-.12.78-.33.44-.42.46-1.1.04-1.54-.02-.02-.03-.03-.05-.03M2.27 8.49a6.21 6.21 0 0 1 6.21-6.23c3.43-.01 6.23 2.78 6.24 6.21 0 1.64-.64 3.21-1.79 4.39a6.21 6.21 0 0 1-8.79.1 6.25 6.25 0 0 1-1.87-4.47" />
      </svg>
    </Icon>
  )
}
export default SearchIcon
