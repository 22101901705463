import gql from 'graphql-tag'

export const DEALERS_QUERY = gql`
  query Dealers(
    $countryCode: String!
    $stateCode: String
    $searchTerm: String
  ) {
    getDealers(
      countryCode: $countryCode
      stateCode: $stateCode
      searchTerm: $searchTerm
    ) {
      ...DealerData
    }
  }

  fragment DealerData on Dealer {
    id
    organizationName
    street1
    street3
    street4
    street5
    city
    phone
    email
    countryCode
    stateCode
    firstName
    lastName
    buildingRoom
    attentionTo
    department
    postalCode
    approverDealerLogonid
    approvalNeeded
    soldToPartner
    shipToPartner
    billToPartner
    payerPartner
    salesOrg
    poNumber
    dealerType
    productGroup
    isPreferredDealer
    availableProducts
  }
`
