import { useUserSession } from './useUserSession'
import { useCountryStatesQuery } from '@src/queries/CountryStatesQuery.generated'

export const useStateName = (
  country: string | null | undefined,
  stateCode: string | null | undefined
) => {
  const { userSession } = useUserSession()
  const userLanguage = userSession?.language?.toLowerCase() || ''
  const userCountry = userSession?.country?.toUpperCase() || ''
  const { data: countryStates } = useCountryStatesQuery({
    variables: {
      country: country || '',
      locale:
        userLanguage === 'en' ? 'en_US' : `${userLanguage}_${userCountry}`,
    },
  })
  if (!country || !stateCode) {
    return null
  }
  const selectedState = countryStates?.getCountryStates?.find(
    (state) => state?.stateCode === stateCode
  )
  return selectedState?.stateName
}
