import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { DealerDataFragment } from '@src/queries/DealersQuery.generated'
import messages from '@src/utils/messages'
import { useStateName } from '@src/utils/useStateName'
import clsx from 'clsx'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    fontSize: 22,
  },
  header: {
    backgroundColor: theme.palette.background.grey,
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    fontSize: 12,
    fontWeight: 900,
  },
  icon: {
    display: 'flex',
    width: '24px',
    height: '24px',
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: '50%',
    [theme.breakpoints.up('md')]: {
      width: '18px',
      height: '18px',
    },
  },
  checkedIcon: {
    backgroundColor: '#ffffff',
    '&:before': {
      display: 'block',
      width: '14px',
      height: '14px',
      backgroundColor: `${theme.palette.primary.main}`,
      borderRadius: '50%',
      transform: 'translateY(4px) transLateX(4px)',
      content: '""',
      [theme.breakpoints.up('md')]: {
        width: '8px',
        height: '8px',
      },
    },
  },
  table: {
    '& td': {
      fontSize: 14,
    },
  },
  tableContainer: {
    [theme.breakpoints.down('sm')]: {
      maxHeight: '50vh',
    },
    maxHeight: 240,
  },
}))

const StateNameCell: FC<{
  country: string
  stateCode: string
}> = ({ country, stateCode }) => {
  const stateName = useStateName(country, stateCode)

  return <div>{stateName}</div>
}

export const SelectDealerTable: FC<{
  dealers: DealerDataFragment[]
  onSelect: (
    selectedDealerId: string,
    selectedDealerName: Maybe<string> | undefined
  ) => void
  selected?: string | null
}> = ({ dealers, onSelect, selected }) => {
  const classes = useStyles()

  return (
    <TableContainer className={classes.tableContainer}>
      <Table stickyHeader size="small" className={classes.table}>
        <colgroup>
          <col style={{ width: '40%' }} />
          <col style={{ width: '15%' }} />
          <col style={{ width: '20%' }} />
          <col style={{ width: '25%' }} />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell className={classes.header}>
              <FormattedMessage {...messages.DEALER_LABEL_DEALER} />
            </TableCell>
            <TableCell className={classes.header}>
              <FormattedMessage {...messages.DEALER_LABEL_BRANCH} />
            </TableCell>
            <TableCell className={classes.header}>
              <FormattedMessage {...messages.DEALER_LABEL_AVAILABLE_PRODUCTS} />
            </TableCell>
            <TableCell className={classes.header}>
              <FormattedMessage {...messages.DEALER_LABEL_PREFECTURE} />
            </TableCell>
            <TableCell className={classes.header}>
              <FormattedMessage {...messages.DEALER_LABEL_ADDRESS} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dealers
            .sort((a, b) =>
              !!a.isPreferredDealer === !!b?.isPreferredDealer
                ? 0
                : !!a.isPreferredDealer
                  ? -1
                  : 1
            )
            .map(
              (
                {
                  id,
                  city,
                  countryCode,
                  organizationName,
                  stateCode,
                  availableProducts,
                  attentionTo,
                },
                idx
              ) => {
                return (
                  <TableRow key={idx}>
                    <TableCell>
                      <label>
                        <Radio
                          className={classes.root}
                          checked={Boolean(selected === id)}
                          onChange={() => onSelect(id, organizationName)}
                          id="selectDealer"
                          checkedIcon={
                            <span
                              className={clsx(
                                classes.icon,
                                classes.checkedIcon
                              )}
                            />
                          }
                          icon={<span className={classes.icon} />}
                        />
                        {organizationName}
                      </label>
                    </TableCell>
                    <TableCell>{attentionTo}</TableCell>
                    <TableCell>{availableProducts}</TableCell>
                    <TableCell>
                      {countryCode && stateCode && (
                        <StateNameCell
                          country={countryCode}
                          stateCode={stateCode}
                        />
                      )}
                    </TableCell>
                    <TableCell>{city}</TableCell>
                  </TableRow>
                )
              }
            )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default SelectDealerTable
