// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type DealersQueryVariables = Types.Exact<{
  countryCode: Types.Scalars['String'];
  stateCode?: Types.InputMaybe<Types.Scalars['String']>;
  searchTerm?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type DealersQuery = { __typename?: 'Query', getDealers: Array<{ __typename?: 'Dealer', id: string, organizationName?: string | null, street1?: string | null, street3?: string | null, street4?: string | null, street5?: string | null, city?: string | null, phone?: string | null, email?: string | null, countryCode?: string | null, stateCode?: string | null, firstName?: string | null, lastName?: string | null, buildingRoom?: string | null, attentionTo?: string | null, department?: string | null, postalCode?: string | null, approverDealerLogonid?: string | null, approvalNeeded?: boolean | null, soldToPartner?: string | null, shipToPartner?: string | null, billToPartner?: string | null, payerPartner?: string | null, salesOrg?: string | null, poNumber?: string | null, dealerType?: string | null, productGroup?: Array<string> | null, isPreferredDealer?: boolean | null, availableProducts?: string | null }> };

export type DealerDataFragment = { __typename?: 'Dealer', id: string, organizationName?: string | null, street1?: string | null, street3?: string | null, street4?: string | null, street5?: string | null, city?: string | null, phone?: string | null, email?: string | null, countryCode?: string | null, stateCode?: string | null, firstName?: string | null, lastName?: string | null, buildingRoom?: string | null, attentionTo?: string | null, department?: string | null, postalCode?: string | null, approverDealerLogonid?: string | null, approvalNeeded?: boolean | null, soldToPartner?: string | null, shipToPartner?: string | null, billToPartner?: string | null, payerPartner?: string | null, salesOrg?: string | null, poNumber?: string | null, dealerType?: string | null, productGroup?: Array<string> | null, isPreferredDealer?: boolean | null, availableProducts?: string | null };

export const DealerDataFragmentDoc = gql`
    fragment DealerData on Dealer {
  id
  organizationName
  street1
  street3
  street4
  street5
  city
  phone
  email
  countryCode
  stateCode
  firstName
  lastName
  buildingRoom
  attentionTo
  department
  postalCode
  approverDealerLogonid
  approvalNeeded
  soldToPartner
  shipToPartner
  billToPartner
  payerPartner
  salesOrg
  poNumber
  dealerType
  productGroup
  isPreferredDealer
  availableProducts
}
    `;
export const DealersDocument = gql`
    query Dealers($countryCode: String!, $stateCode: String, $searchTerm: String) {
  getDealers(countryCode: $countryCode, stateCode: $stateCode, searchTerm: $searchTerm) {
    ...DealerData
  }
}
    ${DealerDataFragmentDoc}`;
export type DealersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DealersQuery, DealersQueryVariables>, 'query'> & ({ variables: DealersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const DealersComponent = (props: DealersComponentProps) => (
      <ApolloReactComponents.Query<DealersQuery, DealersQueryVariables> query={DealersDocument} {...props} />
    );
    

/**
 * __useDealersQuery__
 *
 * To run a query within a React component, call `useDealersQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealersQuery({
 *   variables: {
 *      countryCode: // value for 'countryCode'
 *      stateCode: // value for 'stateCode'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useDealersQuery(baseOptions: ApolloReactHooks.QueryHookOptions<DealersQuery, DealersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<DealersQuery, DealersQueryVariables>(DealersDocument, options);
      }
export function useDealersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DealersQuery, DealersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<DealersQuery, DealersQueryVariables>(DealersDocument, options);
        }
export type DealersQueryHookResult = ReturnType<typeof useDealersQuery>;
export type DealersLazyQueryHookResult = ReturnType<typeof useDealersLazyQuery>;
export type DealersQueryResult = ApolloReactCommon.QueryResult<DealersQuery, DealersQueryVariables>;