import {
  DealerDataFragment,
  useDealersQuery,
} from '@src/queries/DealersQuery.generated'
import { extractData } from '@utils/errorHandler'
import { useCurrentUser } from '@utils/useCurrentUser'
import { useUserSession } from '@utils/useUserSession'
import React, { FC, useEffect } from 'react'

import SelectDealerTable from './SelectDealerTable'

const DealerTableFetchData: FC<{
  selectDealer: (
    id: string,
    organizationName: Maybe<string> | undefined
  ) => void
  selectedDealerId: string | null
  searchTerm?: string
  stateCode?: string
}> = ({ selectDealer, selectedDealerId, searchTerm = '', stateCode }) => {
  const { userSession } = useUserSession()
  const { currentUserState } = useCurrentUser()
  const state = stateCode || String(currentUserState)
  const {
    data: dealersData,
    loading,
    error,
  } = useDealersQuery({
    context: { userSession },
    errorPolicy: 'all',
    variables: {
      countryCode: userSession?.country,
      // When fetching with a term we don't want to also filter results by stateCode
      stateCode: !!searchTerm ? undefined : state,
      searchTerm: searchTerm.toLowerCase(),
    },
  })

  useEffect(() => {
    if (dealersData?.getDealers) {
      const { getDealers: dealersList } = dealersData
      const hasPreferredDealer: DealerDataFragment | null =
        dealersList?.find((item) => Boolean(item.isPreferredDealer)) ?? null

      if (hasPreferredDealer && !selectedDealerId) {
        return selectDealer(
          hasPreferredDealer.id,
          hasPreferredDealer.organizationName
        )
      }

      return selectDealer(dealersList[0].id, dealersList[0].organizationName)
    }
  }, [dealersData])

  if (loading) return <div>Loading...</div>

  if (error) {
    const { displayableError, hasError } = extractData(error)
    console.error(displayableError)
    if (hasError('DEALER_DATA_NOT_FOUND_GENERIC')) {
      return <div>No Dealers Available.</div>
    }
  }

  if (!dealersData?.getDealers) return <div>No Dealers Available.</div>

  return (
    <SelectDealerTable
      dealers={dealersData?.getDealers || []}
      onSelect={selectDealer}
      selected={selectedDealerId}
    />
  )
}

export default DealerTableFetchData
