// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type CountryStatesQueryVariables = Types.Exact<{
  country: Types.Scalars['String'];
  locale: Types.Scalars['String'];
}>;


export type CountryStatesQuery = { __typename?: 'Query', getCountryStates: Array<{ __typename?: 'CountryStates', countryCode?: string | null, locale?: string | null, stateCode?: string | null, stateName?: string | null } | null> };


export const CountryStatesDocument = gql`
    query CountryStates($country: String!, $locale: String!) {
  getCountryStates(country: $country, locale: $locale) {
    countryCode
    locale
    stateCode
    stateName
  }
}
    `;
export type CountryStatesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CountryStatesQuery, CountryStatesQueryVariables>, 'query'> & ({ variables: CountryStatesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CountryStatesComponent = (props: CountryStatesComponentProps) => (
      <ApolloReactComponents.Query<CountryStatesQuery, CountryStatesQueryVariables> query={CountryStatesDocument} {...props} />
    );
    

/**
 * __useCountryStatesQuery__
 *
 * To run a query within a React component, call `useCountryStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountryStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountryStatesQuery({
 *   variables: {
 *      country: // value for 'country'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useCountryStatesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<CountryStatesQuery, CountryStatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<CountryStatesQuery, CountryStatesQueryVariables>(CountryStatesDocument, options);
      }
export function useCountryStatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CountryStatesQuery, CountryStatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<CountryStatesQuery, CountryStatesQueryVariables>(CountryStatesDocument, options);
        }
export type CountryStatesQueryHookResult = ReturnType<typeof useCountryStatesQuery>;
export type CountryStatesLazyQueryHookResult = ReturnType<typeof useCountryStatesLazyQuery>;
export type CountryStatesQueryResult = ApolloReactCommon.QueryResult<CountryStatesQuery, CountryStatesQueryVariables>;